import Paper from "@mui/material/Paper";
import { useCallback, useState } from "react";
import ConfirmButton from "../buttons/ConfirmButton";
import {
  OrderDirection,
  TransferTokenOwnershipProposal,
  TransferTokenOwnershipProposal_OrderBy,
} from "../../../../../apis/__generated__/graphql";
import RevokeProposalButton from "../buttons/RevokeProposalButton";
import ProposalStatusChip from "../../../../shared/ProposalStatusChip";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { useQuery } from "@apollo/client";
import { GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS } from "../../../../../libraries/queries/proposals";
import { ProposalStatus } from "../../../../../libraries/types/proposal";

export interface TransferTokenOwnershipProposalTableProps {}

export default function TransferTokenOwnershipProposalTable() {
  const [selectedRow, setSelectedRow] = useState<
    TransferTokenOwnershipProposal | undefined
  >(undefined);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const {
    data: proposalData,
    loading,
    refetch,
  } = useQuery(GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS, {
    variables: {
      first: paginationModel.pageSize,
      skip: paginationModel.pageSize * paginationModel.page,
      orderDirection:
        sortModel?.[0]?.sort === "asc"
          ? OrderDirection.Asc
          : OrderDirection.Desc,
      orderBy: sortModel?.[0]?.field as TransferTokenOwnershipProposal_OrderBy,

      where: {
        status: ProposalStatus.Pending,
      },
    },
  });

  const proposals = (proposalData?.transferTokenOwnershipProposals ||
    []) as TransferTokenOwnershipProposal[];

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps["onPaginationModelChange"]>
  >((model) => {
    setPaginationModel(model);
  }, []);

  const onSortModelChange = useCallback<
    NonNullable<DataGridProProps["onSortModelChange"]>
  >(
    (model) => {
      setSortModel(model);
    },
    [setSortModel]
  );

  const columns: GridColDef<TransferTokenOwnershipProposal>[] = [
    {
      field: "index",
      headerName: "Index",
      valueGetter: ({ value }) => {
        return `#${value}`;
      },
      width: 120,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.3,
      minWidth: 120,
    },
    {
      field: "newOwner",
      headerName: "New Owner",
      flex: 0.3,
      minWidth: 120,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: ({ value }) => <ProposalStatusChip status={value} />,
    },
    {
      field: "actions",
      type: "actions",
      width: 150,
      getActions: ({ row }) => {
        return [
          <ConfirmButton
            key="Confirm"
            disabled={false}
            onSelectedRow={() => setSelectedRow(row)}
            onUnselectedRow={() => setSelectedRow(undefined)}
            row={selectedRow}
            refetch={refetch}
          />,
          <RevokeProposalButton
            key="Revoke"
            disabled={false}
            onSelectedRow={() => setSelectedRow(row)}
            onUnselectedRow={() => setSelectedRow(undefined)}
            row={selectedRow}
            refetch={refetch}
          />,
        ];
      },
    },
  ];

  return (
    <Paper>
      <DataGridPro
        sx={{
          ".MuiTablePagination-displayedRows": {
            display: "none",
          },
        }}
        pagination
        rows={proposals}
        columns={columns}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={
          proposals.length === 10
            ? paginationModel.pageSize * (paginationModel.page + 1) + 1
            : paginationModel.pageSize * (paginationModel.page + 1)
        }
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
        rowHeight={100}
        paginationModel={paginationModel}
        sortModel={sortModel}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
      />
    </Paper>
  );
}
