import Paper from "@mui/material/Paper";
import { useCallback, useState } from "react";
import ConfirmButton from "../buttons/ConfirmButton";
import { Stack, Typography } from "@mui/material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoneIcon from "@mui/icons-material/Done";
import {
  GrantKeyKeeperProposal,
  GrantKeyKeeperProposal_OrderBy,
  OrderDirection,
} from "../../../../../apis/__generated__/graphql";
import RevokeProposalButton from "../buttons/RevokeProposalButton";
import ProposalStatusChip from "../../../../shared/ProposalStatusChip";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { useQuery } from "@apollo/client";
import { GET_GRANT_KEY_KEEPER_PROPOSALS } from "../../../../../libraries/queries/proposals";
import { ProposalStatus } from "../../../../../libraries/types/proposal";
export interface GrantKeyKeeperProposalTableProps {}

export default function GrantKeyKeeperProposalTable() {
  const [selectedRow, setSelectedRow] = useState<
    GrantKeyKeeperProposal | undefined
  >(undefined);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const {
    data: proposalData,
    refetch,
    loading,
  } = useQuery(GET_GRANT_KEY_KEEPER_PROPOSALS, {
    variables: {
      first: paginationModel.pageSize,
      skip: paginationModel.pageSize * paginationModel.page,
      orderDirection:
        sortModel?.[0]?.sort === "asc"
          ? OrderDirection.Asc
          : OrderDirection.Desc,
      orderBy: sortModel?.[0]?.field as GrantKeyKeeperProposal_OrderBy,

      where: {
        status: ProposalStatus.Pending,
      },
    },
  });

  const proposals = (proposalData?.grantKeyKeeperProposals ||
    []) as GrantKeyKeeperProposal[];

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps["onPaginationModelChange"]>
  >((model) => {
    setPaginationModel(model);
  }, []);

  const onSortModelChange = useCallback<
    NonNullable<DataGridProProps["onSortModelChange"]>
  >(
    (model) => {
      setSortModel(model);
    },
    [setSortModel]
  );

  const columns: GridColDef<GrantKeyKeeperProposal>[] = [
    {
      field: "newKeyKeeper",
      headerName: "New Key Keeper",
      flex: 1,
    },
    {
      field: "confirms",
      headerName: "Confirms",
      width: 150,
      sortable: false,
      renderCell: ({ row }) => {
        const numberOfConfirmations =
          (row.administratorApproved ? 1 : 0) + (row.keyKeeperApproved ? 1 : 0);

        return `${numberOfConfirmations}/2`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: ({ value }) => <ProposalStatusChip status={value} />,
    },
    {
      field: "confirmations",
      headerName: "Confirmations",
      width: 200,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <Typography fontSize={14} display="flex">
              Administrator:{" "}
              {row.administratorApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
            <Typography fontSize={14} display="flex">
              Key Keeper:{" "}
              {row.keyKeeperApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 150,
      getActions: ({ row }) => {
        return [
          <ConfirmButton
            key="Confirm"
            disabled={row.administratorApproved}
            onSelectedRow={() => setSelectedRow(row)}
            onUnselectedRow={() => setSelectedRow(undefined)}
            row={selectedRow}
            refetch={refetch}
          />,
          <RevokeProposalButton
            key="Revoke"
            disabled={false}
            onSelectedRow={() => setSelectedRow(row)}
            onUnselectedRow={() => setSelectedRow(undefined)}
            row={selectedRow}
            refetch={refetch}
          />,
        ];
      },
    },
  ];

  return (
    <Paper>
      <DataGridPro
        sx={{
          ".MuiTablePagination-displayedRows": {
            display: "none",
          },
        }}
        pagination
        rows={proposals}
        columns={columns}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={
          proposals.length === 10
            ? paginationModel.pageSize * (paginationModel.page + 1) + 1
            : paginationModel.pageSize * (paginationModel.page + 1)
        }
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
        rowHeight={100}
        paginationModel={paginationModel}
        sortModel={sortModel}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
      />
    </Paper>
  );
}
