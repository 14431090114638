import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import GrantButton from "./components/buttons/GrantButton";
import AdminTable from "./components/tables/AdminTable";

export default function AdminPage() {
  return (
    <Box>
      <Stack direction="row" sx={{ width: "100%", marginBottom: 2 }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Administrators
        </Typography>
        <GrantButton />
      </Stack>

      <AdminTable />
    </Box>
  );
}
