import Paper from "@mui/material/Paper";
import { useMediaQuery } from "@mui/material";
import theme from "../../../../../theme";
import { useQuery } from "@apollo/client";
import useRoles from "../../../../../hooks/useRoles";
import { GET_ROLES } from "../../../../../libraries/queries/roles";
import { useCallback, useState } from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
} from "@mui/x-data-grid-pro";

export interface KeyKeeperTableProps {}

export default function KeyKeeperTable() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { KEY_KEEPER_ROLE } = useRoles();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const { data, loading } = useQuery(GET_ROLES, {
    variables: {
      first: paginationModel.pageSize,
      skip: paginationModel.pageSize * paginationModel.page,
      where: {
        role: KEY_KEEPER_ROLE,
      },
    },
    skip: !KEY_KEEPER_ROLE,
  });

  const keyKeepers = data?.roles || [];

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps["onPaginationModelChange"]>
  >((model) => {
    setPaginationModel(model);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "account",
      headerName: "Address",
      flex: 1,
      valueGetter: ({ value }) => {
        return isSmallScreen
          ? value.slice(0, 10) + "…" + value.slice(-10)
          : value;
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: ({ row }) => [],
      width: 100,
    },
  ];

  return (
    <Paper>
      <DataGridPro
        sx={{
          ".MuiTablePagination-displayedRows": {
            display: "none",
          },
        }}
        pagination
        rows={keyKeepers}
        columns={columns}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={
          keyKeepers.length === 10
            ? paginationModel.pageSize * (paginationModel.page + 1) + 1
            : paginationModel.pageSize * (paginationModel.page + 1)
        }
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
    </Paper>
  );
}
