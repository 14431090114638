import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import RevokeKeyKeeperProposalTable from "./tables/RevokeKeyKeeperProposalTable";
import AddButton from "./buttons/AddButton";
import ProposalTabs from "../../../shared/ProposalTabs";
import ProceedTable from "./tables/ProceedTable";

export default function RevokeKeyKeeperProposalPage() {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Revoke Key Keeper Proposal
        </Typography>
        <AddButton />
      </Stack>
      <ProposalTabs
        processingTab={<RevokeKeyKeeperProposalTable />}
        proceedTab={<ProceedTable />}
      />
    </Box>
  );
}
