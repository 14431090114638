import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import TransferOwnershipProposalTable from "./tables/TransferOwnershipProposalTable";
import AddButton from "./buttons/AddButton";
import ProposalTabs from "../../../shared/ProposalTabs";
import ProceedTable from "./tables/ProceedTable";

export default function TransferOwnershipProposalPage() {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Transfer Ownership Proposal
        </Typography>
        <AddButton />
      </Stack>
      <ProposalTabs
        processingTab={<TransferOwnershipProposalTable />}
        proceedTab={<ProceedTable />}
      />
    </Box>
  );
}
