import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useCallback, useState } from "react";
import { Button, CircularProgress, Stack } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import { useContract } from "../../../../../hooks/useContract";
import { ZeroAddress, isAddress } from "ethers";
import { errorDecoder } from "../../../../../libraries/utils/error-decoder";
import { useApolloClient } from "@apollo/client";
import { GET_ROLES } from "../../../../../libraries/queries/roles";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface GrantModalProps {
  open: boolean;
  onClose: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

export interface GrantValues {
  address: string;
}

export default function GrantModal({ open, onClose }: GrantModalProps) {
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const contract = useContract();
  const client = useApolloClient();

  const onSubmit = async (data: GrantValues) => {
    try {
      setStep(Steps.Waiting);
      const tx = await contract.grantOperatorRole(data.address);
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      client.refetchQueries({
        include: [GET_ROLES],
      });
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  };

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <Typography variant="h6" component="h2">
              Grant new operator
            </Typography>

            <Typography sx={{ mb: 2 }}>
              {`This actions grant new operator role for new address`}
            </Typography>
            <FormContainer onSuccess={onSubmit}>
              <Stack direction="column" spacing={2}>
                <TextFieldElement
                  name="address"
                  label="New operator's address"
                  validation={{
                    required: true,
                    validate: (value) => {
                      if (!isAddress(value) || value == ZeroAddress)
                        return "Address is not valid";
                      return true;
                    },
                  }}
                />
                <Button type="submit" variant="contained">
                  Grant Operator
                </Button>
              </Stack>
            </FormContainer>
          </Box>
        )}

        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
