import { useCallback, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
import {
  AutocompleteElement,
  DateTimePickerElement,
  FormContainer,
  useWatch,
} from "react-hook-form-mui";
import { Stack } from "@mui/material";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import { useContract } from "../../../../../hooks/useContract";
import { Ownership } from "../../../../../apis/__generated__/graphql";
import dayjs from "dayjs";
import { errorDecoder } from "../../../../../libraries/utils/error-decoder";
import useMaximumExtendPeriod from "../../../../../hooks/useMaximumExtendPeriod";
import { useLazyQuery, useApolloClient } from "@apollo/client";
import { GET_OWNERSHIPS } from "../../../../../libraries/queries/ownerships";
import { OwnershipTypes } from "../../../../../libraries/utils/constants";
import { formatEther } from "ethers";
import debounce from "lodash/debounce";
import uniqBy from "lodash/unionBy";
import { GET_EXTEND_LOCKUP_PERIOD_PROPOSALS } from "../../../../../libraries/queries/proposals";
export interface AddModalProps {
  open: boolean;
  onClose: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 510,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface AddValues {
  selectedOwnership: Ownership;
  deadline: dayjs.Dayjs;
}

export default function AddModal({ open, onClose }: AddModalProps) {
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const contract = useContract();
  const client = useApolloClient();

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);

  const onSubmit = async (data: AddValues) => {
    try {
      setStep(Steps.Waiting);

      const extension = dayjs
        .duration(
          dayjs(data.deadline).unix() -
            dayjs.unix(data.selectedOwnership.lockupDeadline).unix(),
          "seconds"
        )
        .asSeconds();

      const tx = await contract.createExtendLockupPeriodProposal(
        data.selectedOwnership.owner,
        data.selectedOwnership.index,
        extension
      );
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      client.refetchQueries({
        include: [GET_EXTEND_LOCKUP_PERIOD_PROPOSALS],
      });
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <FormContainer onSuccess={onSubmit}>
              <Typography variant="h6" component="h2" sx={{ marginBottom: 1 }}>
                Create Extend Lockup Period Proposal
              </Typography>
              <Box>
                <Form onSubmit={onSubmit} />
              </Box>
            </FormContainer>
          </Box>
        )}
        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

function Form({ onSubmit }: { onSubmit: (data: AddValues) => void }) {
  const { data: maximumExtendPeriod } = useMaximumExtendPeriod();
  const [getVestingData, { data: vestingData, fetchMore, called }] =
    useLazyQuery(GET_OWNERSHIPS, {
      variables: {
        where: {
          status_in: [
            OwnershipTypes.PROVISIONAL,
            OwnershipTypes.VESTING_LOCKUP,
            OwnershipTypes.OWNERSHIP_LOCKUP,
          ],
        },
        first: 200,
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    });

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((search: string) => {
        let [address, index] = search.split(" - #");
        address = address.trim();

        if (address.length % 2 !== 0) {
          return;
        }

        fetchMore({
          variables: {
            where: {
              status_in: [
                OwnershipTypes.PROVISIONAL,
                OwnershipTypes.VESTING_LOCKUP,
                OwnershipTypes.OWNERSHIP_LOCKUP,
              ],
              owner_contains: address,
              index,
            },
          },
          updateQuery(previousQueryResult, { fetchMoreResult }) {
            if (!fetchMoreResult) {
              return previousQueryResult;
            }

            return {
              ...fetchMoreResult,
              ownerships: uniqBy(
                [
                  ...previousQueryResult.ownerships,
                  ...fetchMoreResult.ownerships,
                ],
                (v) => v.id
              ),
            };
          },
        }).catch(console.error);
      }, 300),
    [fetchMore]
  );

  const ownerships = useMemo(
    () => vestingData?.ownerships || [],
    [vestingData]
  );

  const options = useMemo(
    () =>
      ownerships.map((ownership) => ({
        ...ownership,
        label: `${ownership.owner} - #${ownership.index}`,
      })),
    [ownerships]
  );

  const [selectedOwnership] = useWatch({
    name: ["selectedOwnership"],
  });

  const ownership = useMemo(
    () =>
      ownerships.find((ownership) => ownership.id === selectedOwnership?.id),
    [ownerships, selectedOwnership]
  );

  return (
    <Stack direction="column" spacing={2}>
      <AutocompleteElement
        options={options}
        name={"selectedOwnership"}
        label="Ownership"
        required={true}
        autocompleteProps={{
          onOpen: () => {
            if (!called) {
              getVestingData();
            }
          },
          onInputChange: (_, newInputValue, reason) => {
            if (newInputValue && reason === "input") {
              debouncedChangeHandler(newInputValue);
            }
          },
        }}
      />
      <DateTimePickerElement
        name="deadline"
        label="New Deadline"
        disablePast
        disabled={!ownership}
        validation={{
          required: true,
        }}
        minDateTime={dayjs.unix(ownership?.lockupDeadline)}
        maxDateTime={dayjs().add(maximumExtendPeriod, "seconds")}
        ampm={false}
      />
      {ownership && (
        <Typography>
          Amount: {formatEther(ownership.amount).toString()} JOC
        </Typography>
      )}
      <Typography>
        Current deadline:{" "}
        {!ownership
          ? "---"
          : ownership?.lockupDeadline == 0
          ? "NOT SET"
          : dayjs.unix(ownership?.lockupDeadline).format("MM/DD/YYYY, hh:mm A")}
      </Typography>
      <Button type={"submit"} startIcon={<AddIcon />} variant="contained">
        Add
      </Button>
    </Stack>
  );
}
