import { gql } from "../../apis/__generated__";

export const GET_CANCEL_LOCKUP_PROPOSALS = gql(/* GraphQL */ `
  query cancelLockupProposals(
    $block: Block_height
    $first: Int
    $orderBy: CancelLockupProposal_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $where: CancelLockupProposal_filter
  ) {
    cancelLockupProposals(
      block: $block
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      where: $where
    ) {
      id
      owner
      index
      administratorApproved
      keyKeeperApproved
      tokenOwnerApproved
      status
      ownership {
        id
        owner
        index
        amount
        lockupDeadline
        status
      }
    }
  }
`);

export const GET_EXTEND_LOCKUP_PERIOD_PROPOSALS = gql(/* GraphQL */ `
  query extendLockupPeriodProposals(
    $block: Block_height
    $first: Int
    $orderBy: ExtendLockupPeriodProposal_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $where: ExtendLockupPeriodProposal_filter
  ) {
    extendLockupPeriodProposals(
      block: $block
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      where: $where
    ) {
      id
      owner
      index
      extensionPeriod
      administratorApproved
      keyKeeperApproved
      status
      ownership {
        id
        owner
        index
        amount
        lockupDeadline
        status
      }
    }
  }
`);

export const GET_GRANT_KEY_KEEPER_PROPOSALS = gql(/* GraphQL */ `
  query grantKeyKeeperProposals(
    $block: Block_height
    $first: Int
    $orderBy: GrantKeyKeeperProposal_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $where: GrantKeyKeeperProposal_filter
  ) {
    grantKeyKeeperProposals(
      block: $block
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      where: $where
    ) {
      id
      newKeyKeeper
      administratorApproved
      keyKeeperApproved
      status
    }
  }
`);

export const GET_REVOKE_KEY_KEEPER_PROPOSALS = gql(/* GraphQL */ `
  query revokeKeyKeeperProposals(
    $block: Block_height
    $first: Int
    $orderBy: RevokeKeyKeeperProposal_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $where: RevokeKeyKeeperProposal_filter
  ) {
    revokeKeyKeeperProposals(
      block: $block
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      where: $where
    ) {
      id
      keyKeeper
      administratorApproved
      keyKeeperApproved
      status
    }
  }
`);

export const GET_TRANSFER_OWNERSHIP_PROPOSALS = gql(/* GraphQL */ `
  query transferOwnershipProposals(
    $block: Block_height
    $first: Int
    $orderBy: TransferOwnershipProposal_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $where: TransferOwnershipProposal_filter
  ) {
    transferOwnershipProposals(
      block: $block
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      where: $where
    ) {
      id
      newOwner
      administratorApproved
      keyKeeperApproved
      status
    }
  }
`);

export const GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS = gql(/* GraphQL */ `
  query transferTokenOwnershipProposals(
    $block: Block_height
    $first: Int
    $orderBy: TransferTokenOwnershipProposal_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $where: TransferTokenOwnershipProposal_filter
  ) {
    transferTokenOwnershipProposals(
      block: $block
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      where: $where
    ) {
      id
      owner
      index
      newOwner
      status
      ownership {
        id
        owner
        index
        amount
        lockupDeadline
        status
      }
    }
  }
`);

export const GET_UPGRADABLE_PROPOSALS = gql(/* GraphQL */ `
  query upgradeableProposals(
    $block: Block_height
    $first: Int
    $orderBy: UpgradeableProposal_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $where: UpgradeableProposal_filter
  ) {
    upgradeableProposals(
      block: $block
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      where: $where
    ) {
      id
      newImplementation
      ownerApproved
      keyKeeperApproved
      status
    }
  }
`);
