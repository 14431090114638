import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import GrantButton from "./components/buttons/GrantButton";
import ManagerTable from "./components/tables/ManagerTable";

export default function ManagerPage() {
  return (
    <Box>
      <Stack direction="row" sx={{ width: "100%", marginBottom: 2 }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Managers
        </Typography>
        <GrantButton />
      </Stack>

      <ManagerTable />
    </Box>
  );
}
