import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import KeyKeeperTable from "./components/tables/KeyKeeperTable";

export default function KeyKeeperPage() {
  return (
    <Box>
      <Stack direction="row" sx={{ width: "100%", marginBottom: 2 }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          KeyKeepers
        </Typography>
      </Stack>

      <KeyKeeperTable />
    </Box>
  );
}
