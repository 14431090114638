import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import GrantModal from "../modals/GrantModal";

export default function GrantButton() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button startIcon={<AddIcon />} variant="contained" onClick={handleOpen}>
        Grant
      </Button>
      <GrantModal open={open} onClose={handleClose} />
    </>
  );
}
