import { useCallback, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
import { AutocompleteElement, FormContainer } from "react-hook-form-mui";
import { Stack } from "@mui/material";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import { useContract } from "../../../../../hooks/useContract";
import { errorDecoder } from "../../../../../libraries/utils/error-decoder";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { GET_ROLES } from "../../../../../libraries/queries/roles";
import useRoles from "../../../../../hooks/useRoles";
import { debounce, uniqBy } from "lodash";
import { GET_REVOKE_KEY_KEEPER_PROPOSALS } from "../../../../../libraries/queries/proposals";
export interface AddModalProps {
  open: boolean;
  onClose: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface AddValues {
  selectedKeyKeeper: string;
}

export default function AddModal({ open, onClose }: AddModalProps) {
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const contract = useContract();
  const client = useApolloClient();

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);

  const onSubmit = async (data: AddValues) => {
    try {
      setStep(Steps.Waiting);
      const tx = await contract.createRevokeKeyKeeperRoleProposal(
        data.selectedKeyKeeper
      );
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      client.refetchQueries({
        include: [GET_REVOKE_KEY_KEEPER_PROPOSALS],
      });
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <Typography variant="h6" component="h2" sx={{ marginBottom: 1 }}>
              Create Revoke Key Keeper Proposal
            </Typography>
            <Box>
              <FormContainer onSuccess={onSubmit}>
                <Form onSubmit={onSubmit} />
              </FormContainer>
            </Box>
          </Box>
        )}
        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

function Form({ onSubmit }: { onSubmit: (data: AddValues) => void }) {
  const { KEY_KEEPER_ROLE } = useRoles();
  const [getRoles, { data: rolesData, fetchMore, called }] = useLazyQuery(
    GET_ROLES,
    {
      variables: {
        where: {
          role: KEY_KEEPER_ROLE,
        },
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    }
  );

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((search: string) => {
        const address = search.trim();

        if (address.length % 2 !== 0) {
          return;
        }

        fetchMore({
          variables: {
            where: {
              role: KEY_KEEPER_ROLE,
              account_contains: address,
            },
          },
          updateQuery(previousQueryResult, { fetchMoreResult }) {
            if (!fetchMoreResult) {
              return previousQueryResult;
            }

            return {
              ...fetchMoreResult,
              roles: uniqBy(
                [...previousQueryResult.roles, ...fetchMoreResult.roles],
                (v) => v.id
              ),
            };
          },
        }).catch(console.error);
      }, 300),
    [fetchMore, KEY_KEEPER_ROLE]
  );

  const keyKeepers = useMemo(() => rolesData?.roles || [], [rolesData]);

  const options = useMemo(
    () =>
      keyKeepers.map((keyKeeper) => ({
        ...keyKeeper,
        label: keyKeeper.account,
      })),
    [keyKeepers]
  );

  return (
    <Stack direction="column" spacing={2}>
      <AutocompleteElement
        options={options}
        name={"selectedKeyKeeper"}
        label="Key keeper's address"
        required={true}
        autocompleteProps={{
          onOpen: () => {
            if (!called) {
              getRoles();
            }
          },
          onInputChange: (_, newInputValue, reason) => {
            if (newInputValue && reason === "input") {
              debouncedChangeHandler(newInputValue);
            }
          },
        }}
      />
      <Button type={"submit"} startIcon={<AddIcon />} variant="contained">
        Create
      </Button>
    </Stack>
  );
}
