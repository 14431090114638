import Paper from "@mui/material/Paper";
import { useMediaQuery } from "@mui/material";
import { Stack, Typography } from "@mui/material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoneIcon from "@mui/icons-material/Done";
import {
  OrderDirection,
  TransferOwnershipProposal,
  TransferOwnershipProposal_OrderBy,
} from "../../../../../apis/__generated__/graphql";
import theme from "../../../../../theme";
import ProposalStatusChip from "../../../../shared/ProposalStatusChip";
import { useCallback, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TRANSFER_OWNERSHIP_PROPOSALS } from "../../../../../libraries/queries/proposals";
import { ProposalStatus } from "../../../../../libraries/types/proposal";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-pro";

export interface ProceedTableProps {}

export default function ProceedTable() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const { data: proposalData, loading } = useQuery(
    GET_TRANSFER_OWNERSHIP_PROPOSALS,
    {
      variables: {
        first: paginationModel.pageSize,
        skip: paginationModel.pageSize * paginationModel.page,
        orderDirection:
          sortModel?.[0]?.sort === "asc"
            ? OrderDirection.Asc
            : OrderDirection.Desc,
        orderBy: sortModel?.[0]?.field as TransferOwnershipProposal_OrderBy,

        where: {
          status_not: ProposalStatus.Pending,
        },
      },
    }
  );

  const proposals = (proposalData?.transferOwnershipProposals ||
    []) as TransferOwnershipProposal[];

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps["onPaginationModelChange"]>
  >((model) => {
    setPaginationModel(model);
  }, []);

  const onSortModelChange = useCallback<
    NonNullable<DataGridProProps["onSortModelChange"]>
  >(
    (model) => {
      setSortModel(model);
    },
    [setSortModel]
  );

  const columns: GridColDef<TransferOwnershipProposal>[] = [
    {
      field: "newOwner",
      headerName: "New Owner",
      flex: 1,
      valueGetter: ({ value }) => {
        return isSmallScreen
          ? value.slice(0, 10) + "…" + value.slice(-10)
          : value;
      },
    },
    {
      field: "confirms",
      headerName: "Confirms",
      width: 150,
      sortable: false,
      renderCell: ({ row }) => {
        const numberOfConfirmations =
          (row.administratorApproved ? 1 : 0) + (row.keyKeeperApproved ? 1 : 0);

        return `${numberOfConfirmations}/2`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: ({ value }) => <ProposalStatusChip status={value} />,
    },
    {
      field: "confirmations",
      headerName: "Confirmations",
      width: 200,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <Typography fontSize={14} display="flex">
              Administrator:{" "}
              {row.administratorApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
            <Typography fontSize={14} display="flex">
              Key Keeper:{" "}
              {row.keyKeeperApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  return (
    <Paper>
      <DataGridPro
        sx={{
          ".MuiTablePagination-displayedRows": {
            display: "none",
          },
        }}
        pagination
        rows={proposals}
        columns={columns}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={
          proposals.length === 10
            ? paginationModel.pageSize * (paginationModel.page + 1) + 1
            : paginationModel.pageSize * (paginationModel.page + 1)
        }
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
        rowHeight={100}
        paginationModel={paginationModel}
        sortModel={sortModel}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
      />
    </Paper>
  );
}
